.mainContainer {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 98px);
}

.bodyContainer {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.controlsContainer {
  min-width: 150px;
  display: flex;
  justify-content: flex-end;
}

.nav {
  margin-left: 60px;
  position: fixed;
  top: 135px;
  left: 0;
  text-overflow: ellipsis;
  border-radius: 2px;
  padding: 0 5px;
  width: 200px;
  background-color: #EAEAEA;
  z-index: 1;
  overflow-y: scroll;
  height: calc(100vh - 134px);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:global(.dark) .nav {
  background-color: #2C2C2C;
}

.nav::-webkit-scrollbar {
  display: none;
}

.sideNavContainer {
  padding: 5px;
  background-color: #f6f7f9;
}

:global(.dark) .sideNavContainer {
  background-color: #2F343C;
}

.setupContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #EAEAEA;
  margin-top: 10px;
  margin-left: 192px;
}

:global(.dark) .setupContainer {
  background-color: #2C2C2C;
}

.titleBar {
  width: 96%;
  position: fixed;
  top: 49px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  z-index: 1;
  border-bottom: 1px solid #787878;
  background-color: #EAEAEA;
}

:global(.dark) .titleBar {
  background-color: #2C2C2C;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow: hidden;
  width: 100%;
}

.titleColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 320px;
}

.titleColumn :nth-child(2) {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.titleColumnDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.titleLabel {
  font-size: 0.9em;
}

.titleValue {
  font-size: 1.5em;
}

.titleValue.year {
  width: 75px;
}

.titleValue.event {
  width: 50px;
}

.suitSelectContainer {
  margin-bottom: 0 !important;
  margin-left: 10px !important;
}

.addSetupsDiv {
  min-width: 150px;
}

.addSetupsContainer {
  margin-bottom: 0 !important;
  margin-left: 20px !important;
  width: 140px !important;
  min-width: 140px !important;
}

.branchSelectContainer {
  gap: 3px !important;
  margin: 10px 0;
}

.branchSelectPopover {
  max-width: 155px;
  white-space: nowrap;
  overflow: hidden;
}

.runLabel {
  font-style: italic;
}
