.mainContainer {
  display: flex;
  flex-direction: column;
}

.paddingLeft {
  padding-left: 5px;
}

.inlineInputs {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.inlineHalf {
  flex: 1 1 50%;
}

.quarterWidth {
  width: 35%;
}

.percentWidth85 {
  width: 85%;
}

.marginLeft10px {
  margin-left: 10px;
}

.inline {
  flex: 1 1;
}

.inlineLabel {
  vertical-align: middle;
  text-wrap: nowrap;
}

.buttonsOnRight {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.simOutlineItemCell {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.simOutlineItemCell .deleteButton {
  align-items: right;
}

.tableActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
}

.textWithEditButton {
  margin-left: 10px;
  margin-right: 10px;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bulkRowActionsContainer {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
}

.table {
  table-layout: fixed;
  width: 100%;
}

.table th,
.table td {
  vertical-align: middle !important;
  overflow-x: hidden;
}

.dndButton {
  cursor: grab !important;
  background-color: transparent;
  border: none;
}

.rowSelectCheckbox {
  margin: 0 !important;
}

.saveButton {
  margin-bottom: 5px;
  width: 170px;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
