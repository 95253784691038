.navMenu {
  list-style: none;
  padding-left: 0;
  margin: 20px 0px 0px 20px;
}

.navMenu li a {
  display: block; /* Used to fill the wrapping <li> */
  text-decoration: none;
  padding: 5px;
  color: #FFF;
}

.navMenu li a:hover {
  color: #686868;
}

.navMenu li::marker {
  color: #FFF;
}

.activeNavMenuItem {
  background-color: #3A3A3A;
}

.activeNavMenuItem:hover {
  color: #FFF !important;
}

.subMenu {
  list-style: disclosure-closed;
}

.navigationDrawer {
  background-color: #2C2C2C !important;
}
