.container {
  display: flex;
  height: calc(100vh - 65px);
  padding-top: 40px;
}

.categoriesListContainer {
  flex-grow: 1;
  padding: 0 20px;
  overflow-y: scroll;
}

.categoryListHeading {
  margin: 15px 0 5px 0 !important;
  border-bottom: 1px solid grey;
  padding-bottom: 5px !important;
}

.categoryListRule {
  margin: 5px 0 !important;
}

.categoryList {
  column-count: 3;
  list-style: none;
  margin: 0;
  padding: 0 10px;
}

.categoryList > li {
  margin: 4px 0;
  padding: 2px 0;
}

.configLink {
  display: block;
  color: black !important;
}

.configLink:hover {
  color: #828282 !important;
  text-decoration: none !important;
}

:global(.dark) .configLink {
  color: white !important;
}

:global(.dark) .configLink:hover {
  color: #828282 !important;
}
