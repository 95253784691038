.settingsList {
  padding-left: 1rem;
  margin: 20px 20px 0px 0px;
}

.settingsIcon {
  color: white;
  margin-right: 1rem;
}

.settingsListItem {
  color: white;
  display: flex;
  justify-content: space-between;
}

.apexSetupDownloaderLink {
  display: flex;
}

.settingsDrawer {
  background-color: #2C2C2C !important;
}