main {
  background-color: #EAEAEA !important;
  overflow: auto;
}

main.dark {
  background-color: #2C2C2C !important;
  overflow: auto;
}

.content {
  padding: 1rem;
  min-height: 100vh;
}

.setupView {
  padding: 0;
}

.toolbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}