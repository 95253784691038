.mainContainer {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 98px);
  padding-top: 100px;
}

.ruitSelectContainer {
  display: flex;
  margin: 5px !important;
}

.controlsContainer {
  min-width: 150px;
  justify-content: flex-end;
  display: flex;
  margin: 5px !important;
}
.titleButtons {
  display: flex;
  min-width: 200px;
}

.marginBottom {
  margin-bottom: 20px;
}

.addRunButton {
  margin-left: auto;
  height: 25%;
  margin-right: 5px;
}

.saveButton {
  height: 25%;
}

.runsContainer {
  margin-bottom: 10px;
  margin-top: 10px;
}

.runContainer {
  display: flex;
}

.deleteButton {
  padding: 15px !important
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
}

.runOptionButton {
  flex: 1;
}

.createRunContainerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createRunContainerRow:first-of-type {
  margin-bottom: 10px;
}

.runSessionLabel {
  min-width: 120px;
}
